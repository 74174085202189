import React from "react";

const ThankModal = ({ closeModal }) => {
  return <div className="modal">
    <div className="modal-box fadeInDown">
      <div className='header'>Thank you for subscribing to our newsletter!</div>
      <div className='info'>Please contact us at <a href="mailto:lmintsolutions@gmail.com">lmintsolutions@gmail.com</a> to further discuss any of your integration needs.</div>
      <button className="close-button" onClick={closeModal}>Close</button>
    </div>
  </div>
}

export default ThankModal;