import React from "react";

const Header = ({ menus }) => {
  const getActiveMenu = (name) => {
    const indicate = window.location.href.split('/')[3];
    return !indicate && name === "home" ? true : indicate.includes(name);
  }
  return (
    <div className="header">
      <div className="logo" />
      <div className="header-right">
        {menus
          ? menus.map((menu, idx) => <a key={idx} className={getActiveMenu(menu.display) ? "active" : ""} href={menu.url}>{menu.display}</a>)
          : <div>
            <a className={getActiveMenu("home") ? "active" : ""} href="#home">Home</a>
            <a className={getActiveMenu("pricing") ? "active" : ""} href="#pricing">Pricing</a>
            <a className={getActiveMenu("about") ? "active" : ""} href="#about">About</a>
            <a className={getActiveMenu("apps") ? "active" : ""} href="#apps">Apps</a>
          </div>
        }

        <div className='login' onClick={() => {
          window.location.href = "https://app.lmints.io";
        }}>Login</div>
      </div>
    </div>
  )
}

export default Header;