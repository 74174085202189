import moment from "moment";
export const settings =
{
    _id: "5e0f1d10db0a604e44b8a08d",
    menus: [
        {
            _id: "5e0f1d11db0a604e44b8a08e",
            display: "home",
            url: "/",
            createdAt: "2020-01-03T10:53:05.240Z",
            updatedAt: "2020-01-13T15:02:01.082Z",
            __v: 0,
            id: "5e0f1d11db0a604e44b8a08e"
        },
        {
            _id: "5e0f1d11db0a604e44b8a08f",
            display: "pricing",
            url: "#pricing",
            createdAt: "2020-01-03T10:53:05.241Z",
            updatedAt: "2020-01-13T15:02:01.085Z",
            __v: 0,
            id: "5e0f1d11db0a604e44b8a08f"
        },
        {
            _id: "5e0f1d11db0a604e44b8a091",
            display: "apps",
            url: "#apps",
            createdAt: "2020-01-03T10:53:05.241Z",
            updatedAt: "2020-01-13T15:02:01.084Z",
            __v: 0,
            id: "5e0f1d11db0a604e44b8a091"
        }
    ],
    headline: {
        _id: "5e0f1d12db0a604e44b8a096",
        title: "Streamline your business processes so you can focus on what really matters",
        description1: "At Simplify eCommerce, we have a passion for technology and automation and helping people! Our customers are of utmost importance to us! We love working with businesses to simplify and automate their business processes in the eCommerce world of Shopify and BigCommerce, so they can focus on building their online businesses.",
        description2: "We want to remove the administrative tasks around owning a web store by integrating Shopify and BigCommerce with Quickbooks online and desktop versions along with the cloud accounting software, Xero. We are continuously looking for more opportunities to help business owners improve their processes, so please don’t hesitate to ask about other integration opportunities if they are not listed on our page. <br/> Or maybe you want to start an online store but don’t know where to be begin, we can help too! We have a team with extensive website development experience ready and willing to help!",
        createdAt: "2020-01-03T10:53:06.396Z",
        updatedAt: "2020-01-13T15:02:02.431Z",
        __v: 0,
        id: "5e0f1d12db0a604e44b8a096"
    },
    pricing: [
        {
            _id: "5e0f1d13db0a604e44b8a098",
            name: "Start",
            description: "Ideal for small business",
            details: "- Connect any <b>2 apps</b> - Additional apps for an <b>extra fee</b> - Less than 24 hours support response time during normal business hours - <b>Up to 30 documents/month</b>",
            price: 0,
            createdAt: "2020-01-03T10:53:07.545Z",
            updatedAt: "2020-01-13T15:02:04.765Z",
            __v: 0,
            id: "5e0f1d13db0a604e44b8a098",
            link: "https://apps.shopify.com/simplify-ecommerce",
        },
        {
            _id: "5e0f1d13db0a604e44b8a099",
            name: "Growing",
            description: "Most Popular",
            details: "- Connect any <b>2 apps</b> - Additional apps for an <b>extra fee</b> - Less than 24 hours support response time during normal business hours - <b>Up to 400 documents/month</b>",
            price: 20,
            createdAt: "2020-01-03T10:53:07.546Z",
            updatedAt: "2020-01-13T15:02:04.759Z",
            __v: 0,
            id: "5e0f1d13db0a604e44b8a099",
            link: "https://apps.shopify.com/simplify-ecommerce",
        },
        {
            _id: "5e0f1d13db0a604e44b8a09a",
            name: "Enterprise",
            price: 50,
            description: "Ideal for multi-channel business",
            details: "- Connect any <b>3 apps</b> - Additional apps for an <b>reduced fee</b> - Less than 24 hours support response time during normal business hours - <b>Unlimited documents/month</b>",
            createdAt: "2020-01-03T10:53:07.546Z",
            updatedAt: "2020-01-13T15:02:04.762Z",
            __v: 0,
            id: "5e0f1d13db0a604e44b8a09a",
            link: "https://apps.shopify.com/simplify-ecommerce",
        }
    ],
    integrations: [
        {
            _id: "5e0f1d14db0a604e44b8a09e",
            type: "Ecommerce",
            apps: [
                {
                    _id: "5e0f1d15db0a604e44b8a0a5",
                    name: "shopify",
                    createdAt: "2020-01-03T10:53:09.040Z",
                    updatedAt: "2020-01-13T15:02:06.629Z",
                    __v: 0,
                    id: "5e0f1d15db0a604e44b8a0a5"
                },
                {
                    _id: "5e0f1d15db0a604e44b8a0a6",
                    name: "bigcommerce",
                    createdAt: "2020-01-03T10:53:09.040Z",
                    updatedAt: "2020-01-13T15:02:06.628Z",
                    __v: 0,
                    id: "5e0f1d15db0a604e44b8a0a6"
                }
            ],
            createdAt: "2020-01-03T10:53:08.687Z",
            updatedAt: "2020-01-13T15:02:07.689Z",
            __v: 1,
            id: "5e0f1d14db0a604e44b8a09e"
        },
        {
            _id: "5e0f1d14db0a604e44b8a09f",
            type: "Accounting Software",
            apps: [
                {
                    _id: "5e0f1d15db0a604e44b8a0a3",
                    name: "qb",
                    createdAt: "2020-01-03T10:53:09.034Z",
                    updatedAt: "2020-01-13T15:02:06.644Z",
                    __v: 0,
                    id: "5e0f1d15db0a604e44b8a0a3"
                },
                {
                    _id: "5e0f1d15db0a604e44b8a0a4",
                    name: "xero",
                    createdAt: "2020-01-03T10:53:09.035Z",
                    updatedAt: "2020-01-13T15:02:06.645Z",
                    __v: 0,
                    id: "5e0f1d15db0a604e44b8a0a4"
                }
            ],
            createdAt: "2020-01-03T10:53:08.688Z",
            updatedAt: "2020-01-13T15:02:07.729Z",
            __v: 1,
            id: "5e0f1d14db0a604e44b8a09f"
        },
        {
            _id: "5e0f1d14db0a604e44b8a0a0",
            type: "File System/Middleware",
            description: "For file system integrations, we produce a standard XML, CSV or EDI file to work with your existing business systems. We can provide a webservice client to automatically pull down documents.",
            apps: [
                {
                    _id: "5e0f1d15db0a604e44b8a0a1",
                    name: "gdrive",
                    createdAt: "2020-01-03T10:53:09.028Z",
                    updatedAt: "2020-01-13T15:02:06.650Z",
                    __v: 0,
                    id: "5e0f1d15db0a604e44b8a0a1"
                },
                {
                    _id: "5e0f1d15db0a604e44b8a0a2",
                    name: "azure",
                    createdAt: "2020-01-03T10:53:09.029Z",
                    updatedAt: "2020-01-13T15:02:06.652Z",
                    __v: 0,
                    id: "5e0f1d15db0a604e44b8a0a2"
                }
            ],
            createdAt: "2020-01-03T10:53:08.688Z",
            updatedAt: "2020-01-13T15:02:07.746Z",
            __v: 1,
            id: "5e0f1d14db0a604e44b8a0a0"
        }
    ],
    footer: {
        _id: "5e0f1d17db0a604e44b8a0b0",
        // owner: `Copyright © ${moment().year()}, LM Integration Solutions, LLC. Email: contact@lmints.io`,
        owner: `Copyright © ${moment().year()}, LM Integration Solutions, LLC. `,
        email: 'contact@lmints.io',
        menus: [
            {
                _id: "5e0f1d17db0a604e44b8a0b2",
                display: "Privacy Policy",
                url: "/privacy",
                createdAt: "2020-01-03T10:53:11.811Z",
                updatedAt: "2020-01-13T15:02:11.696Z",
                __v: 0,
                id: "5e0f1d17db0a604e44b8a0b2"
            },
            {
                _id: "5e0f1d17db0a604e44b8a0b3",
                display: "Term of use",
                url: "/termsofservice",
                createdAt: "2020-01-03T10:53:11.811Z",
                updatedAt: "2020-01-13T15:02:11.699Z",
                __v: 0,
                id: "5e0f1d17db0a604e44b8a0b3"
            }
        ],
        createdAt: "2020-01-03T10:53:11.474Z",
        updatedAt: "2020-01-13T15:02:12.782Z",
        __v: 1,
        id: "5e0f1d17db0a604e44b8a0b0"
    },
    createdAt: "2020-01-03T10:53:04.874Z",
    updatedAt: "2020-01-13T15:02:14.953Z",
    __v: 5,
    id: "5e0f1d10db0a604e44b8a08d"
}
