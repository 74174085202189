import React from 'react';
import Header from "../components/header";
import Home from "../components/home";
import Pricing from "../components/pricing";
import AppIntegrations from "../components/appIntegrations";
import AppFooter from "../components/app_footer";
import { settings } from "../common/settings";

function HomePage() {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    setData(settings);
  }, [])

  return data && (
    <div className="App">
      <Header menus={data.menus} />
      <Home headlineTitle={data.headline.title} headlineDescription={data.headline.description1} subDescription={data.headline.description2} />
      <Pricing pricings={data.pricing} />
      <AppIntegrations integrations={data.integrations} />
      <AppFooter footer={data.footer} />
    </div>
  );
}

export default HomePage;
