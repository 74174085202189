import React from "react";

const Loader = () => {
  return <div className="modal blur" trole="dialog">
    <div className="modal-dialog modal-dialog-centered" role="document">
      <div class="lds-dual-ring"></div>
    </div>
  </div>;
}

export default Loader;