import React from "react";
import { Link } from "react-router-dom";

const AppFooter = ({ footer }) => {
  const email = footer ? footer.email : 'lmintsolutions@gmail.com';
  const owner = footer ? footer.owner : "Copyright © 2019, LM Integration Solutions, LLC. ";
  return (
    <div className='footer'>
      <div className='background' />
      <div className='info'>
        {footer && <div className='left-link'>
          {footer.menus && footer.menus.map((item, idx) => <Link key={idx} to={item.url}>{item.display}</Link>)}
        </div>}
        <div className='right-info'>
          {owner}
          <a href={`mailto:${email}`}>Email: {email}</a>
        </div>
      </div>
    </div>
  )
}

export default AppFooter;