import React from 'react';
import { Route, Routes } from "react-router-dom";
import HomePage from './pages/HomePage';
import { Terms } from './pages/Terms';
import { Privacy } from './pages/Privacy';
import ScrollToTop from "./common/useScrollTop"
import './styles/frontend.css';

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="/privacy" exact={true} element={<Privacy />} />
        <Route path="/terms" exact={true} element={<Terms />} />
        <Route path="/termsofservice" exact={true} element={<Terms />} />
      </Routes>
    </>
  );
}

export default App;
