import React from "react";

const Pricing = ({ pricings }) => {
  return pricings ? (
    <div className='pricing' id="pricing">
      <div className='background'>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1200" height="1040" viewbox="0 0 1200 1039.2304845413264">
          <path fill="#000000" d="M24.999999999999993 562.9165124598851Q0 519.6152422706632 24.999999999999993 476.31397208144125L275 43.30127018922194Q300 0 350 0L850 0Q900 0 925 43.30127018922194L1175 476.31397208144125Q1200 519.6152422706632 1175 562.9165124598851L925 995.9292143521045Q900 1039.2304845413264 850 1039.2304845413264L350 1039.2304845413264Q300 1039.2304845413264 275 995.9292143521045Z"></path>
        </svg>
      </div>
      <div className='title'>Pricing</div>
      <div className='detail'>
        {pricings.map((item, idx) => (
          <div className="columns" key={idx}>
            <div className="price">
              <div className='header'>{item.name}</div>
              <div className='price-detail'><span className='sign'>$</span><span className='number'>{item.price}</span>/ month</div>
              <div className="description">{item.description}</div>
              <ul>
                {item.details && item.details.split('-').slice(1, item.details.split('-').length).map((detail, idx) => <li key={idx} dangerouslySetInnerHTML={{ __html: detail }}></li>)}
              </ul>
              <div className="price-button" onClick={() => {
                window.location.href = item.link;
              }}>Start now</div>
            </div>
          </div>
        ))}
      </div>
      <div className='note'>*Please contact us for any Integrations not listed on our Apps page</div>
    </div>
  ) : <div></div>
}

export default Pricing;