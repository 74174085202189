import React from "react";
import ThankModal from "../components/thank_modal";
import Loader from "../components/loader";

const Home = ({ headlineTitle, headlineDescription, subDescription }) => {
  const [email, setEmail] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [inputFocused, setInputFocused] = React.useState(false);
  return (
    <div className="overlay-content">
      {showModal && <ThankModal closeModal={() => setShowModal(false)} />}
      {loading && <Loader />}
      {/* <div className='background'>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1200" height="1040" viewbox="0 0 1200 1039.2304845413264">
          <path fill="#2B9B97" d="M24.999999999999993 562.9165124598851Q0 519.6152422706632 24.999999999999993 476.31397208144125L275 43.30127018922194Q300 0 350 0L850 0Q900 0 925 43.30127018922194L1175 476.31397208144125Q1200 519.6152422706632 1175 562.9165124598851L925 995.9292143521045Q900 1039.2304845413264 850 1039.2304845413264L350 1039.2304845413264Q300 1039.2304845413264 275 995.9292143521045Z"></path>
        </svg>
      </div> */}
      <div className="title">{headlineTitle || "Streamline your business processes so you can focus on what really matters"}</div>
      <div className='first-paragraph' dangerouslySetInnerHTML={{ __html: headlineDescription }} />
      <div className="first-col">
        <div className='paragraph' dangerouslySetInnerHTML={{ __html: subDescription }}></div>
        <div className="inputGroup">
          <input
            type="text"
            className="input-email"
            placeholder={!inputFocused && "Enter your email"}
            aria-label="Email Address"
            aria-describedby="basic-addon2"
            value={email}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
            onChange={(event) => setEmail(event.target.value)} />
          <div className="input-group-append" onClick={() => {
            if (!email) { alert("Please enter your email!"); return; }
            setLoading(true);
            fetch('https://cors-anywhere.herokuapp.com/https://us20.api.mailchimp.com/3.0/lists/1dc5c8dc8e/members', {
              method: 'post',
              headers: new Headers({
                'Authorization': 'Basic 59a4272de62b44f3cae11e71e5cf81de-us20',
              }),
              body: JSON.stringify({
                "email_address": email,
                "status": "subscribed"
              })
            }).then(response => response.json())
              .then(() => { setShowModal(true); setLoading(false); })
              .catch(err => { alert(err); setLoading(false); });
          }}>
            <div className="btn-subcribe">Subscribe</div>
          </div>
        </div>
        <div className='note'>* You can withdraw your consent at any time</div>
      </div>
      <div className='second-col'/>
    </div>
  )
}

export default Home;