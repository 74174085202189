import React from "react";
import Partner from "../components/partner";
import shopify from "../assets/logo-s.png";
import bigcommerce from "../assets/logo-b.png";
import qb from "../assets/App-logo-QuickBooks.png";
import xero from "../assets/logo-xero.png";
import gdrive from "../assets/Google_Drive_logo.png";
import azure from "../assets/windows-update-icon-png-file-19.png";

const LogoSrc = {
  shopify: shopify,
  bigcommerce: bigcommerce,
  qb: qb,
  xero: xero,
  gdrive: gdrive,
  azure: azure,
}

const AppIntegrations = ({ integrations }) => {
  return integrations ? (
    <div className="app-integrations">
      {/* <div className='background'>
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1200" height="1040" viewbox="0 0 1200 1039.2304845413264">
          <path fill="#2B9B97" d="M24.999999999999993 562.9165124598851Q0 519.6152422706632 24.999999999999993 476.31397208144125L275 43.30127018922194Q300 0 350 0L850 0Q900 0 925 43.30127018922194L1175 476.31397208144125Q1200 519.6152422706632 1175 562.9165124598851L925 995.9292143521045Q900 1039.2304845413264 850 1039.2304845413264L350 1039.2304845413264Q300 1039.2304845413264 275 995.9292143521045Z"></path>
        </svg>
      </div> */}
      <div className='title'>App Integrations</div>
      <div className='app-detail'>
        {integrations.map((item, idx) => (
          <div key={idx} className="partner-group">
            <div className='partner-info'>
              <div className="top-label">{item.type}</div>
              <div className="description" dangerouslySetInnerHTML={{ __html: item.description }} />
            </div>
            <div className="partner">
              {item.apps && item.apps.map((app, idx) => <Partner key={idx} logo={LogoSrc[app.name] || app.img_src} />)}
            </div>
          </div>
        ))}
      </div>
      <div className='note'>*Please contact us for any Integrations not listed on our Apps page</div>
    </div>
  ) : <div></div>
}

export default AppIntegrations;